import React from "react";
import img1 from "../../assets/blogsImages/Rectangle 24891.svg";
import "./BlogDetailPage.css";
import facebook from "./SVGRepo_iconCarrier.svg";
import linkedIn from "./linekdin.svg";
import mail from "./mail.svg";
import youtube from "./Layer_1.svg";
import insta from "./Frame.svg";
const BlogDetailPage = (props) => {
  const { isMobile, pageData, updatedArr } = props;
  console.log(pageData);

  return (
    <>
      <div className="blogPageDetailsContainer">
        <div className="blogPageDetailsContainer-divL">
          <div
            style={
              isMobile
                ? { display: "flex", flexDirection: "column", gap: "0.5rem" }
                : { display: "flex", flexDirection: "column", gap: "2rem" }
            }
          >
            <a
              target="_blank"
              href="https://www.facebook.com/HekaHealthyYou?mibextid=ZbWKwL"
            >
              <img
                style={isMobile ? { width: "18px" } : { width: "48px" }}
                src={facebook}
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/heka-healthy-you/"
            >
              <img
                style={isMobile ? { width: "18px" } : { width: "48px" }}
                src={linkedIn}
              />
            </a>
            <a target="_blank" href="mailto:support@hekahealth.in">
              <img
                style={isMobile ? { width: "18px" } : { width: "48px" }}
                src={mail}
              />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/@hekahealthyyouprivatelimited/featured"
            >
              <img
                style={isMobile ? { width: "18px" } : { width: "48px" }}
                src={youtube}
              />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/heka__health?igsh=aXdhaTdlencyNnc="
            >
              <img
                style={isMobile ? { width: "18px" } : { width: "48px" }}
                src={insta}
              />
            </a>
          </div>
        </div>
        <div className="blogPageDetailsContainer-divR">
          <img src={pageData?.bannerImage} />
          <h5 className="blogPageDetails-Head">{pageData?.blogHeading}</h5>
          <div className="blogPageDetails-align1">
            <img
              src={img1}
              style={{
                height: "2.5rem",
                width: "2.5rem",
                borderRadius: "100%",
              }}
            />
            <div>
              <h6 className="blogPageDetails-dName">{pageData?.writerName}</h6>
              <p className="blogPageDetails-designation">
              {pageData?.writerDesignation}
              </p>
            </div>
          </div>
          <p className="blogPageDetails-pDateNTime">
          {pageData?.dateofPosting} |  {pageData?.readingTime} min read  
          </p>
          <p className="blogPageDetails-content1">
            In today's fast-paced world, where technological advancements
            continue to redefine the boundaries of healthcare, one demographic
            stands to benefit significantly: our beloved seniors. As the ageing
            population grows, so does the necessity for tailored care solutions
            that prioritise their safety, independence, and overall well-being.
          </p>
          <div>
            {updatedArr.map((item, index) => {
              return (
                <>
                  <div
                    style={
                      isMobile
                        ? { padding: "16px 0px 8px 0px" }
                        : { padding: "32px 0px 16px 0px" }
                    }
                    className="blogPageDetails-contentHead"
                  >
                    {item.heading}
                  </div>
                  <>
                    {item.data.map((it, i) => {
                      return (
                        <p className="blogPageDetails-content1">
                          {i + 1}. {it}
                        </p>
                      );
                    })}
                  </>

                  <img src={item.image} />
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailPage;
