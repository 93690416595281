import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import facebook from "../../../assets/headerIcons/fb1.svg";
import linkedIn from "../../../assets/headerIcons/linekdin1.svg";
import mail from "../../../assets/headerIcons/mail1.svg";
import twitter from "../../../assets/headerIcons/twitter 11.svg";
import youtube from "../../../assets/headerIcons/Frame 4273200201.svg";
import insta from "../../../assets/headerIcons/Frame1.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import tick from "./tick.svg";
const ContactUs = (props) => {
  const {
    isMobile,
    show,
    slotRef,
    calendarRef,
    handleDateDialog,
    selectedDate,
    showDateDialog,
    handleDateChange,
    handleTimeDialog,
    showTimeDialog,
    timeSlot,
    selectedSlot,
    handleSlot,
    name,
    setName,
    mobileNumber,
    handleMobile,
    mobileErr,
    showDialog,
    handleSendOtp,
    handleClose,
    inputRefs,
    handleChange,
    handleKeyDown,
    otp,
    formatTime,
    timeLeft,
    handleSubmitOpt,
    handleSendEnquiry,
    showSuccessMsg,
    handleProceed,
    pageIndex,
    handleDownloadBrochure,
  } = props;

  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    if (
      pageIndex === "1" ||
      pageIndex === "2" ||
      pageIndex === "6" ||
      pageIndex === "7" ||
      pageIndex === "8" ||
      pageIndex === "9" ||
      pageIndex === "10"
    ) {
      setShowBtn(true);
    }
  }, []);

  return (
    <>
      <div className="contactUsPage">
        <div className="contactUsPage-div1">
          <h4 className="contactUsPage-head1">
            We will get in touch with you!
          </h4>
          {isMobile && (
            <p className="contactUsPage-text1">
              Your well-being is our priority. <br /> Together, let's navigate
              every step with care and compassion.
            </p>
          )}

          {isMobile && (
            <div className="contact-whatsapp">
              <p className="contact-whatsapp1">or WhatsApp /call on</p>
              <div className="contact-whatsapp-div">
                <CallIcon htmlColor="#215071" sx={{ fontSize: "8.96px" }} />
                <p className="contact-whatsapp-div1">801-0711-010 </p>
              </div>
            </div>
          )}

          <div className="contactUsPage-formMain">
            {show ? (
              <div className="contactUsPage-form">
                <div className="contactUsPage-form1">
                  <label className="contactUsPage-formLabel">Name</label>
                  <input
                    type="text"
                    className="contactUsPage-formInput"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled
                    placeholder="John Doe"
                  />
                </div>
                <div className="contactUsPage-form1">
                  <label className="contactUsPage-formLabel">
                    Enter your mobile number
                  </label>
                  <div className="contactUsPage-formInput1Div">
                    <div className="contactUsPage-formInput1Div1">
                      <div className="contactUsPage-formInput1Div-code">
                        +91
                      </div>
                      <ArrowDropDownIcon htmlColor="#838383" />
                    </div>
                    <input
                      type="text"
                      maxLength={10}
                      value={mobileNumber}
                      disabled
                      className="contactUsPage-formInput1"
                      placeholder="00000 00000"
                    />
                  </div>
                  {mobileErr && <div>{mobileErr}</div>}
                </div>
              </div>
            ) : (
              <div className="contactUsPage-form2">
                <div className="contactUsPage-form1A">
                  <label className="contactUsPage-formLabel">Name</label>
                  <input
                    type="text"
                    className="contactUsPage-formInput"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="John Doe"
                  />
                </div>
                <div className="contactUsPage-form1A">
                  <label className="contactUsPage-formLabel">
                    Enter your mobile number
                  </label>
                  <div className="contactUsPage-formInput1Div">
                    <div className="contactUsPage-formInput1Div1A">
                      <div className="contactUsPage-formInput1Div-code">
                        +91
                      </div>
                      <ArrowDropDownIcon htmlColor="#838383" />
                    </div>
                    <input
                      type="text"
                      maxLength={10}
                      value={mobileNumber}
                      onChange={handleMobile}
                      className="contactUsPage-formInput1AA"
                      placeholder="00000 00000"
                    />
                  </div>
                  {mobileErr && <div>{mobileErr}</div>}
                </div>
              </div>
            )}
            {show && (
              <div className="contactUsPage-form">
                <div className="contactUsPage-form1">
                  <label className="contactUsPage-formLabel">
                    Preferred Date
                  </label>
                  <div
                    onClick={handleDateDialog}
                    className="contactUsPage-formInput1Div2"
                  >
                    <div className="contactUsPage-formInput1Div-code">
                      {selectedDate.format("DD/MM/YYYY")}
                    </div>
                    <ArrowDropDownIcon htmlColor="#838383" />
                  </div>
                  {showDateDialog && (
                    <div ref={calendarRef} className="contactUsPage-calendar">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["StaticDatePicker"]}>
                          <DemoItem>
                            <StaticDatePicker
                              defaultValue={dayjs(new Date())}
                              value={selectedDate}
                              onChange={handleDateChange}
                              renderInput={(params) => <input {...params} />}
                              minDate={dayjs()}
                              sx={{
                                borderRadius: "0rem 1rem",
                                border: " 0.4px solid #215071",
                              }}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  )}
                </div>
                <div className="contactUsPage-form1">
                  <label className="contactUsPage-formLabel">
                    Preferred Time
                  </label>
                  <div
                    onClick={handleTimeDialog}
                    className="contactUsPage-formInput1Div2"
                  >
                    <div className="contactUsPage-formInput1Div-code">
                      {selectedSlot}
                    </div>
                    <ArrowDropDownIcon htmlColor="#838383" />
                  </div>
                  {showTimeDialog && (
                    <div ref={slotRef} className="contactUsPage-Time">
                      <h6 className="contactUsPage-TimeHead">
                        Choose your time slot
                      </h6>

                      <div className="contactUsPage-TimeHead-align">
                        {timeSlot.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                              }}
                            >
                              <input
                                type="radio"
                                value={item}
                                checked={selectedSlot === item}
                                onChange={() => handleSlot(item)}
                              />
                              <label
                                style={isMobile ? { fontSize: "10px" } : {}}
                              >
                                {item}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {show ? (
            <button
              onClick={() => handleSendEnquiry()}
              className="contactUsPage-Btn"
            >
              Send Enquiry
            </button>
          ) : (
            <button
              onClick={() => handleSendOtp()}
              className="contactUsPage-Btn"
            >
              Send OTP
            </button>
          )}
        </div>
        <div className="contactUsPage-div2">
          <div className="contactUsPage-div2-div">
            <p className="contactUsPage-rightDivText">
              Your well-being is our priority. <br /> Together, let's navigate
              every step with care <br /> and compassion.
            </p>

            <div>
              <div className="contactUsPage-rightDiv contactUsPage-div2-divdiv">
                <div className="contactUsPage-rightDiv1">
                  <CallIcon htmlColor="#fff" />
                  <p className="contactUsPage-rightDivText">801-0711-010</p>
                </div>
                <div className="contactUsPage-rightDiv1">
                  <EmailIcon htmlColor="#ffff" />
                  <p className="contactUsPage-rightDivText">
                    support@hekahealth.com
                  </p>
                </div>
              </div>

              <div className="contactUsPage-iconDiv contactUsPage-div2-divdiv">
                <a
                  target="_blank"
                  href="https://www.facebook.com/HekaHealthyYou?mibextid=ZbWKwL"
                >
                  <img src={facebook} />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/heka-healthy-you/"
                >
                  <img src={linkedIn} />
                </a>
                <a target="_blank" href="mailto:support@hekahealth.in">
                  <img src={mail} />
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/@hekahealthyyouprivatelimited/featured"
                >
                  <img src={youtube} />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/heka__health?igsh=aXdhaTdlencyNnc="
                >
                  <img src={insta} />
                </a>
                {/* <a
                  target="_blank"
                  href="https://x.com/hekahealthyyou?t=bIVd2LIDtxooXT_znSYiMA&s=08"
                >
                  <img src={twitter} />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showBtn && (
        <button
          onClick={() => {
            handleDownloadBrochure(pageIndex);
          }}
          className="contact-brochure"
        >
          Download Brochure
        </button>
      )}

      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        {showSuccessMsg ? (
          <div className="contactUsPage-dialog">
            <div onClick={handleClose} className="contactUsPage-dialogClose">
              <CloseIcon />
            </div>

            <img src={tick} />
            <h1 className="contactUsPage-dialogHead">
              Verification Successful
            </h1>

            <p
              style={{ color: "#000000", fontSize: "14px", lineHeight: "18px" }}
              className="contactUsPage-dialogText"
            >
              Your details have been verified.
              <br />
              Click continue to secure account.
            </p>
            <button onClick={handleProceed} className="contactUsPage-dialogBtn">
              Proceed
            </button>
          </div>
        ) : (
          <div className="contactUsPage-dialog">
            <div onClick={handleClose} className="contactUsPage-dialogClose">
              <CloseIcon />
            </div>
            <h1 className="contactUsPage-dialogHead">OTP Verification</h1>
            <p className="contactUsPage-dialogText">
              Enter the OTP sent to : +91 {mobileNumber}
            </p>
            <div className="contactUsPage-dialogBoxs">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="tel"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  ref={inputRefs[index]}
                  className="contactUsPage-dialogInput"
                />
              ))}
            </div>
            <p className="contactUsPage-dialogText">
              {formatTime(timeLeft)} Mins
            </p>
            <p className="contactUsPage-dialogText">
              Didn't receive the code?{" "}
              <span className="contactUsPage-dialogText1">Re-send</span>
            </p>
            <button
              onClick={handleSubmitOpt}
              className="contactUsPage-dialogBtn"
            >
              Submit
            </button>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default ContactUs;
