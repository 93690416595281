import React, { createRef, useState } from "react";
import img2 from "../../assets/safetyImages/ST BG Image.png";
import "./SafetyTrainingServicePage.css";
import mobileImgMain from "./Image & BG.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import img6 from "./Rectangle 2514.png";
import img7 from "./Rectangle 2514 (1).png";
import img8 from "./Rectangle 2514 (2).svg";
import img9 from "./Rectangle 2577.svg";
import img10 from "./image 63.svg";
import img11 from "./image 64.svg";

const SafetyTrainingServicePage = (props) => {
  const { isMobile, arr, arr1, arr3, handleEnquireNow } = props;
  const mySlider = createRef();

  const handleNext1 = () => {
    mySlider.current.slickNext();
  };

  const handlePrev1 = () => {
    mySlider.current.slickPrev();
  };

  const setting = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const setting2 = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const alignImages = () => {
    return arr1.map((item) => {
      return (
        <div className="safetyTrainingContainer1-Div">
          <div className="safetyTrainingContainer1-Div1">
            <img src={item.arrImg} className="safetyTrainingContainer1-Img" />
          </div>
          <div className="safetyTrainingContainer1-Text">{item.text}</div>
        </div>
      );
    });
  };

  const alignImages2 = () => {
    return arr3.map((item) => {
      return (
        <img
          src={item}
          alt="images"
          style={isMobile ? { width: "72px", height: "72px" } : {}}
        />
      );
    });
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (touchStartX === null) return;
    const touchMoveX = e.touches[0].clientX;
    const difference = touchStartX - touchMoveX;
    if (difference > 50) {
      nextImage();
    } else if (difference < -50) {
      prevImage();
    }
    setTouchStartX(null);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === arr1.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? arr1.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Safety Training
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, our safety training equips individuals with <br />
              essential skills, from basics to advanced techniques, <br />{" "}
              ensuring comprehensive protection.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img2} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Safety Training
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, our safety training equips individuals with
              essential skills, from <br /> basics to advanced techniques,
              ensuring comprehensive protection.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <div className="safetyTrainingContainer">
        <div className="safetyTrainingContainer-div">
          <h1 className="safetyTrainingContainer-Head1">Respond first,</h1>
          <h1 className="safetyTrainingContainer-Head2">help save lives</h1>

          <p className="safetyTrainingContainer-Text">
            In India, 30% of mortalities occur due to delays in emergency care.
            It can take upto 20 minutes for proper medical help to arrive at the
            scene.To counter these delays, Heka Health wants to turn a bystander
            into the first responder who is equipped to handle such emergencies.
            We focus on improving attitudes of public as well as their ability
            to respond to emergencies.
          </p>
          <p
            className="safetyTrainingContainer-Text"
            style={{ fontStyle: "italic" }}
          >
            We aim for aff society wherein 1 out of 5 people are equipped to
            provide first aid and basic life support to help save lives.
          </p>
        </div>
      </div>

      <div className="safetyTrainingContainer1">
        {isMobile ? (
          <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={() => setTouchStartX(null)}
            className="safetyTrainingContainer1-Div"
          >
            <div className="safetyTrainingContainer1-Div1">
              <img src={arr1[currentIndex].arrImg} />
            </div>
            <div className="safetyTrainingContainer1-Text">
              {arr1[currentIndex].text}
            </div>
          </div>
        ) : (
          <Slider ref={mySlider} arrows={false} {...setting}>
            {alignImages()}
          </Slider>
        )}

        {isMobile ? (
          <></>
        ) : (
          <>
            <div style={{ left: "5%" }} className="homePageContainer2-arrow1">
              <ArrowRightAltIcon onClick={handlePrev1} />
            </div>
            <div style={{ right: "5%" }} className="homePageContainer2-arrow2">
              <ArrowRightAltIcon onClick={handleNext1} />
            </div>
          </>
        )}
      </div>

      <div
        style={
          isMobile ? { padding: "32px 0px 0px 0px" } : { padding: "16px 0px" }
        }
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">What do we offer?</h4>
      </div>

      <div className="elderlyCarePageContainer3">
        {arr.map((item, index) => {
          return (
            <div key={index} className="elderlyCarePageContainer3-card">
              <div>
                <img
                  alt="images"
                  src={item.arrImg}
                  className="elderlyCarePageContainer3-cardImg"
                />
              </div>

              <h5 className="elderlyCarePageContainer3-cardHead">
                {item.head}
              </h5>
              <p className="elderlyCarePageContainer3-cardText2">{item.text}</p>
            </div>
          );
        })}
      </div>

      <h4
        style={
          isMobile ? { padding: "16px 0px 0px 0px" } : { padding: "16px 0px" }
        }
        className="homePageContainer4-headMain"
      >
        Our Solutions
      </h4>

      <div className="safetyTrainingContainer2">
        <div className="safetyTrainingContainer2-div1">
          <h1 className="safetyTrainingContainer2-Head">Basic Life Support</h1>
          <p className="safetyTrainingContainer2-Text">
            Basic life support (BLS) is essential medical care provided during
            life-threatening emergencies, including CPR, rescue breathing, and
            AED use, to maintain circulation and oxygenation until advanced
            medical help arrives. HHY provides specialised training in BLS which
            enables people to learn and equip themselves with these skills and
            step up to respond to an emergency and save lives.
          </p>
          {!isMobile && (
            <button className="safetyTrainingContainer-btn">Enquire Now</button>
          )}
        </div>
        <div className="safetyTrainingContainer2-div2">
          <img
            src={isMobile ? img8 : img6}
            style={isMobile ? {} : { width: "100%", height: "100%" }}
          />
          {isMobile && (
            <button
              style={{ margin: "16px 0px 0px 0px", width: "100%" }}
              className="safetyTrainingContainer-btn"
            >
              Enquire Now
            </button>
          )}
        </div>
      </div>

      <div className="safetyTrainingContainer2">
        <div className="safetyTrainingContainer2-div1">
          <h1 className="safetyTrainingContainer2-Head">First Aid</h1>
          <p className="safetyTrainingContainer2-Text">
            First aid refers to the initial assistance or treatment given to
            someone who has been injured or suddenly taken ill before
            professional medical help arrives. HHY provides specialised training
            in First aid which consists of different situations which will help
            people learn the basics and step up to respond to an emergency and
            stop the injuries from escalating.
          </p>
          {!isMobile && (
            <button className="safetyTrainingContainer-btn">Enquire Now</button>
          )}
        </div>
        <div className="safetyTrainingContainer2-div2">
          <img
            src={isMobile ? img9 : img7}
            style={{ width: "100%", height: "100%" }}
          />
          {isMobile && (
            <button
              style={{ margin: "16px 0px 0px 0px", width: "100%" }}
              className="safetyTrainingContainer-btn"
            >
              Enquire Now
            </button>
          )}
        </div>
      </div>

      <h4
        style={isMobile ? {} : { padding: "48px 0px 0px 0px" }}
        className="homePageContainer4-headMain"
      >
        Our Recognitions
      </h4>

      <div className="safetyTrainingContainer4">
        <div className="safetyTrainingContainer4-div">
          <div style={isMobile ? { height: "84px" } : { height: "172px" }}>
            <img src={img10} style={isMobile ? { height: "72px" } : {}} />
          </div>
          <p className="safetyTrainingContainer4-text">
            “They are a team of experienced professionals and are equipped to
            impart such trainings. The benefit of early intervention can still
            prove life-saving, and the volume of people available to assist them
            in meeting response time is even greater. We wish them success for
            their future trainings and making an impact with their community
            outreach initiatives .”
          </p>
          <p className="safetyTrainingContainer4-text">
            -All India Council for Technical Education
          </p>
        </div>
        <div
          style={isMobile ? { paddingTop: "16px" } : {}}
          className="safetyTrainingContainer4-div"
        >
          <div style={isMobile ? { height: "84px" } : { height: "172px" }}>
            <img src={img11} />
          </div>
          <p className="safetyTrainingContainer4-text">
            “These sessions may be conducted in all the partner institutions to
            focus and get hands-on Basic Life Support. This will make our youth
            more employable and confident when they join the workforce. The
            traubed responders will help stabilize the patient and provide the
            appropriate care, including CPR, until the ambulance arrives to take
            over the treatment.”
          </p>
          <p className="safetyTrainingContainer4-text">
            -National Educational Alliance for Technology
          </p>
        </div>
      </div>

      <h4
        style={isMobile ? {} : { padding: "48px 0px 0px 0px" }}
        className="homePageContainer4-headMain"
      >
        Our Collaborations
      </h4>

      <div className="safetyTrainingContainer3">
        <Slider ref={mySlider} arrows={false} {...setting2}>
          {alignImages2()}
        </Slider>
      </div>
    </>
  );
};

export default SafetyTrainingServicePage;
