import React from "react";
import "./EventPage.css";
import img from "../../assets/eventsImages/Events BG.svg";
import mobileImgMain from "../../assets/eventsImages/Events BG Mobl.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import image1 from "../../assets/eventsImages/Rectangle 2736.svg";
import image2 from "../../assets/eventsImages/Rectangle 2737.svg";
import image3 from "../../assets/eventsImages/Rectangle 2738.svg";
import icon1 from "../../assets/eventsImages/calendar.svg";
import icon2 from "../../assets/eventsImages/time.svg";
import icon3 from "../../assets/eventsImages/location.svg";

const EventPage = (props) => {
  const { isMobile, handleEnquireNow, arr, selectedIndex, handleIndex } = props;
  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />
          <div className="consultationPageContainer1Content">
            <h1
              style={{ textAlign: "center" }}
              className="consultationPageContainer1Content-Head"
            >
              Our Events
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, our learning environment features <br /> engaging
              content, supportive instructors, interactive <br /> activities,
              and real-world applications, fostering confidence <br /> and
              motivation.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Our Events
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, our learning environment features engaging
              content,
              <br /> supportive instructors, interactive activities, and
              real-world applications, <br /> fostering confidence and
              motivation.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <h4 className="event-containerhead">Our Past Trainings</h4>

      <div className="event-container">
        {arr.map((item, index) => {
          return (
            <div
              key={index}
              className={
                selectedIndex === index ? "event-BoxBorder" : "event-Box"
              }
              // style={{ boxShadow: "0px 2px 15px 0px #0000001A" }}
            >
              <div onClick={() => handleIndex(index)} className="event-div">
                <div className="event-head">{item.name}</div>
                {selectedIndex === index ? (
                  <div
                    style={{
                      backgroundColor: "#215071",
                      color: "#fff",
                      borderRadius: "100%",
                      padding: isMobile ? "0px" : "9px",
                      boxShadow: "0px 3.95px 12.63px 0px #080F340F",
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      borderRadius: "100%",
                      padding: isMobile ? "0px" : "9px",
                      boxShadow: "0px 3.95px 12.63px 0px #080F340F",
                    }}
                  >
                    <KeyboardArrowRightIcon />
                  </div>
                )}
              </div>
              {selectedIndex === index && (
                <div style={isMobile ? {} : { width: "90%" }}>
                  <div className="event-alignImage2">
                    <div className="event-alignImage1">
                      <img src={icon1} alt="calendar" className="event-icon" />
                      <div className="event-text1">{item.date}</div>
                    </div>
                    <div className="event-alignImage1">
                      <img src={icon2} alt="calendar" className="event-icon" />
                      <div className="event-text1">{item.time}</div>
                    </div>
                  </div>
                  <div className="event-alignImage2">
                    <div className="event-alignImage1">
                      <img src={icon3} alt="calendar" className="event-icon" />
                      <div className="event-text1">{item.location}</div>
                    </div>
                  </div>
                  <p className="event-text">{item.description}</p>
                  <div className="event-alignImage">
                    <img src={item.eventImage1} className="event-Image" />
                    <img src={item.eventImage2} className="event-Image" />
                    <img src={item.eventImage3} className="event-Image" />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default EventPage;
